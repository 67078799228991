import React from 'react'
import HelmetComponent from '../components/HelmetComponent'
import Schedule from '../components/Live/Schedule'
import Information from '../components/Live/Information'
import FoodMenu from '../components/Live/FoodMenu'
import FloorPlan from '../components/Live/FloorPlan'
import Contact from '../components/Live/Contact'
import LiveFooter from '../components/Live/LiveFooter'
import LiveSideBar from '../components/Live/LiveSideBar'

import '../components/style.scss'
import '../components/Live/live.scss'

class Live extends React.Component {
  constructor() {
    super()
    this.state = {
      activeButton: 'schedule',
      isSidebarOpen: false,
    }
    this.setActive = this.setActive.bind(this)
    this.toggleOpen = this.toggleOpen.bind(this)
  }

  setActive(buttonName) {
    this.setState({ activeButton: buttonName })
  }

  toggleOpen(event) {
    this.setState(prevState => {
      return {
        isSidebarOpen: !prevState.isSidebarOpen,
      }
    })
    if (event.target.nodeName === 'A') {
      window.location.replace(event.target.getAttribute('href'))
    }
  }

  render() {
    const sideLinks = (
      <div className="nav-links">
        <button
          className="section-link"
          onClick={() => this.setActive('schedule')}>
          SCHEDULE
        </button>
        <button className="section-link" onClick={() => this.setActive('info')}>
          INFORMATION
        </button>
        <button className="section-link" onClick={() => this.setActive('menu')}>
          FOOD MENU
        </button>
        <button className="section-link" onClick={() => this.setActive('fp')}>
          FLOOR PLAN
        </button>
        <button
          className="section-link"
          onClick={() => this.setActive('contact')}>
          CONTACT
        </button>
      </div>
    )

    return (
      <div>
        <HelmetComponent />
        <LiveSideBar
          links={sideLinks}
          isOpen={this.state.isSidebarOpen}
          onClick={this.toggleOpen}
        />
        <div className="nav-title">
          {'uOttaHack'}
          <sup className="nav-superscript">2019</sup>
        </div>
        <div className="button-group">
          <button
            className={
              this.state.activeButton === 'schedule'
                ? 'button-group-btn active'
                : 'button-group-btn'
            }
            onClick={() => this.setActive('schedule')}>
            Schedule
          </button>
          <button
            className={
              this.state.activeButton === 'info'
                ? 'button-group-btn active'
                : 'button-group-btn'
            }
            onClick={() => this.setActive('info')}>
            Information
          </button>
          <button
            className={
              this.state.activeButton === 'menu'
                ? 'button-group-btn active'
                : 'button-group-btn'
            }
            onClick={() => this.setActive('menu')}>
            Food Menu
          </button>
          <button
            className={
              this.state.activeButton === 'fp'
                ? 'button-group-btn active'
                : 'button-group-btn'
            }
            onClick={() => this.setActive('fp')}>
            Floor Plan
          </button>
          <button
            className={
              this.state.activeButton === 'contact'
                ? 'button-group-btn active'
                : 'button-group-btn'
            }
            onClick={() => this.setActive('contact')}>
            Contact
          </button>
        </div>
        <div className="content-wrapper">
          {this.state.activeButton === 'schedule' && <Schedule />}
          {this.state.activeButton === 'info' && <Information />}
          {this.state.activeButton === 'menu' && <FoodMenu />}
          {this.state.activeButton === 'fp' && <FloorPlan />}
          {this.state.activeButton === 'contact' && <Contact />}
        </div>
        <LiveFooter />
      </div>
    )
  }
}

export default Live
