import React from 'react'
import Menu from '../Menu'

import './liveSideBar.scss'

class LiveSideBar extends React.Component {
  render() {
    return (
      <>
        <Menu onClick={this.props.onClick} isOpen={this.props.isOpen} />
        <div
          className={'sidebar ' + this.props.isOpen}
          onClick={this.props.onClick}>
          {this.props.links}
        </div>
      </>
    )
  }
}

export default LiveSideBar
