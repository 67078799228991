import React from 'react'

export default Contact => (
  <div>
    <h2>General Inquiries</h2>
    <h3>Paul Lee - Organizer</h3>
    <p>(519) - 410 - 8070</p>
    <h3>Hamza Basari - Organizer</h3>
    <p>(416) - 803 - 7665</p>
    <h3>Natesh Kukreja - Organizer</h3>
    <p>(613) - 858 - 3897</p>
    <br />
    <p>*For life threatening emergencies please call 911</p>
  </div>
)
