import React from 'react'
import slack from '../../images/slackbutton.png'
import devpost from '../../images/devpbutton.png'

import './live.scss'

export default Information => (
  <div>
    <div className="img-links">
      <a href="https://join.slack.com/t/uottahack2019/shared_invite/enQtNTI5Mjk4ODQxNTM5LTYxMDIwZjBmNDcwNDdjNzZjZGUxOTNiYjRhYzFlMzU4YzZmYTU5ZGI3ZDg4MzNiMmNmZDg0ZTRhZWFkMzBlNzc">
        <img src={slack} alt="" />
      </a>
      <a href="https://uottahack2019.devpost.com/">
        <img src={devpost} alt="" />
      </a>
    </div>
    <h2>Wifi Networks</h2>
    <div className="food-section">
      <p className="food-title">eduroam</p>
      <p className="company-catering">Campus wide network</p>
      <ul>
        <li>Feel free to join this network if your school email supports it</li>
      </ul>
    </div>
    <div className="food-section">
      <p className="food-title">guOttawa</p>
      <p className="company-catering">CRX building only</p>
      <ul>
        <li>Open network connection with no authentication required</li>
      </ul>
    </div>
    <h2>Challenges</h2>
    <div className="food-section">
      <a
        href="http://orchestra.solace.cloud/challenge/"
        style={{ textDecoration: 'none', color: '#086788' }}>
        <p className="food-title">Solace</p>
      </a>
      <p className="company-catering">Prize - Nintendo Switch</p>
    </div>
    <div className="food-section">
      <p className="food-title">Scalar</p>
      <p className="company-catering">Prize - Apple TV 4k</p>
    </div>
  </div>
)
