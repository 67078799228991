import React from 'react'
import fp from '../../images/floorplan.jpg'

const outerDivStyle = {
  textAlign: 'center',
}

const imgStyle = {
  maxWidth: '100%',
  height: 'auto',
  paddingBottom: '5%',
}

export default FloorPlan => (
  <div style={outerDivStyle}>
    <img style={imgStyle} src={fp} alt="" />
  </div>
)
