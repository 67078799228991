import React from 'react'

import './live.scss'

export default FoodMenu => (
  <div>
    <h2 style={{ textAlign: 'center' }}>Food Menu</h2>
    <p id="coffee">
      Coffee will be served all weekend{' '}
      <span role="img" aria-label="coffee-emoji">
        ☕️
      </span>
    </p>
    <h3>DAY 1: Saturday, February 9</h3>
    <div className="food-section">
      <p className="food-title">Breakfast</p>
      <p className="company-catering" />
      <ul>
        <li>Baked goods such as croissants, toast, muffins and so on</li>
        <li>Fruit</li>
      </ul>
    </div>
    <div className="food-section">
      <p className="food-title">Lunch</p>
      <p className="company-catering">RAON (Korean Bibimbap)</p>
      <ul>
        <li>
          <strong>Beef: </strong>beef, white/black rice, black pepper, garlic,
          ginger, green onion, onion, pear, rice wine, sesame oil, sesame seed,
          soy sauce, white sugar, plum vinegar, green cabbage, carrot, spinach,
          mushroom, egg, kimchi{' '}
        </li>
        <li>
          <strong>Chicken: </strong>chicken, white/black rice, apple, bell
          pepper, garlic, ginger, chili paste, green onion, onion, rice wine,
          sesame oil, sesame seed, soy sauce, white sugar, plum vinegar, green
          cabbage, carrot, spinach, mushroom, egg, kimchi
        </li>
        <li>
          <strong>Pork: </strong>pork, white/black rice, apple, bell pepper,
          garlic, ginger, chili paste, green onion, onion, rice wine, sesame
          oil, sesame seed, soy sauce, white sugar, vegetable oil, plum vinegar,
          green cabbage, carrot, spinach, mushroom, egg, kimchi
        </li>
        <li>
          <strong>Tofu: </strong>tofu, white/black rice, garlic, onion, salt,
          gluten-free soy sauce, white sugar, vegetable oil, green cabbage,
          carrot, spinach, mushroom, egg, kimchi
        </li>
      </ul>
    </div>
    <div className="food-section">
      <p className="food-title">Dinner</p>
      <p className="company-catering">Cozmos Souvlaki (Greek)</p>
      <ul>
        <li>Chicken skewers (2 pieces) or Falafel (8 pieces)</li>
        <li>
          <strong>Rice: </strong> carrots, celery, onion
        </li>
        <li>
          <strong>Potatoes: </strong> tossed in garlic and margarine
        </li>
        <li>
          <strong>Salad: </strong>onions, tomatoes, lettuce, olives, feta cheese
          (some without)
        </li>
        <li>Tzatziki Sauce</li>
        <li>Pita</li>
      </ul>
    </div>
    <h3>DAY 2: Sunday, February 10</h3>
    <div className="food-section">
      <p className="food-title">Breakfast</p>
      <p className="company-catering" />
      <ul>
        <li>Bagels & cream cheese</li>
        <li>Fruit</li>
        <li>Veggies</li>
      </ul>
    </div>
    <div style={{ marginBotton: '25% !important' }} className="food-section">
      <p className="food-title">Lunch</p>
      <p className="company-catering">Banh Mi (Vietnamese Sandwiches)</p>
      <ul>
        <li>
          <strong>Assorted: </strong> assorted pork meats, pork pate, spread
          with eggs, pickled carrots, coriander
        </li>
        <li>
          <strong>Chicken: </strong> chicken, spread with eggs, pickled carrots,
          coriander
        </li>
        <li>
          <strong>Vegetarian tofu: </strong> tofu, spread with eggs, pickled
          carrots, coriander
        </li>
        <li>
          <strong>Vegan tofu: </strong>tofu, soya sauce, pickled carrots,
          coriander
        </li>
      </ul>
    </div>
  </div>
)
